import { db } from '../firebaseConfig';
import { collection, query, where, orderBy, limit, startAfter, getDocs } from 'firebase/firestore';

export const getNews = async (lastDoc = null, count = 100) => {
    let newsQuery;

    if (lastDoc) {
        newsQuery = query(
            collection(db, 'news'),
            where('continue_processing', '==', true),
            orderBy('admin_post_message_datetime', 'desc'),
            startAfter(lastDoc),
            limit(count)
        );
    } else {
        newsQuery = query(
            collection(db, 'news'),
            where('continue_processing', '==', true),
            orderBy('admin_post_message_datetime', 'desc'),
            limit(count)
        );
    }

    try {
        const newsSnapshot = await getDocs(newsQuery);
        const news = newsSnapshot.docs.map(doc => ({
            id: doc.id,
            question: doc.data().clickbait_title_ukrainian,
            answer: doc.data().short_answer_ukrainian,
            fullText: doc.data().telegram_post_ukrainian,
            image: doc.data().top_image,
            source: doc.data().url
        }));
        const lastVisible = newsSnapshot.docs[newsSnapshot.docs.length - 1];
        return { news, lastVisible };
    } catch (error) {
        console.error("Error fetching news: ", error);
        return { news: [], lastVisible: null };
    }
};
