import React, { useState, useEffect, useCallback } from 'react';
import ScrollFeed from './ScrollFeed';
import NewsItem from './NewsItem';
import '../styles/NewsApp.css';
import { getNews } from '../utils/newsUtils';

const NewsApp = () => {
    const [news, setNews] = useState([]);
    const [lastDoc, setLastDoc] = useState(null);
    const [currentID, setCurrentID] = useState(0);
    const [visibleContent, setVisibleContent] = useState({});

    useEffect(() => {
        const fetchInitialNews = async () => {
            const { news: initialNews, lastVisible } = await getNews();
            setNews(initialNews);
            setLastDoc(lastVisible);
        };
        fetchInitialNews();
    }, []);

    const fetchMoreNews = useCallback(async () => {
        if (lastDoc) {
            const { news: newNews, lastVisible } = await getNews(lastDoc);
            setNews((prevNews) => [...prevNews, ...newNews]);
            setLastDoc(lastVisible);
        }
    }, [lastDoc]);

    useEffect(() => {
        if (currentID >= news.length - 30) {
            fetchMoreNews();
        }
    }, [currentID, news, fetchMoreNews]);

    const handlePreviousElement = useCallback(() => {
        setCurrentID((prevID) => Math.max(0, prevID - 1));
    }, []);

    const handleNextElement = useCallback(() => {
        setCurrentID((prevID) => Math.min(news.length - 1, prevID + 1));
    }, [news.length]);

    const toggleContentVisibility = useCallback((id) => {
        setVisibleContent((prev) => ({
            ...prev,
            [id]: !prev[id]
        }));
    }, []);

    const getElement = useCallback((index) => {
        if (index < 0 || index >= news.length) return null;
        return (
            <NewsItem
                key={news[index].id}
                {...news[index]}
                isContentVisible={!!visibleContent[news[index].id]}
                onToggleContent={() => toggleContentVisibility(news[index].id)}
            />
        );
    }, [news, visibleContent, toggleContentVisibility]);

    return (
        <div className="news-app-container">
            <ScrollFeed
                previousElement={getElement(currentID - 1)}
                currentElement={getElement(currentID)}
                nextElement={getElement(currentID + 1)}
                onPreviousElement={handlePreviousElement}
                onNextElement={handleNextElement}
            />
        </div>
    );
};

export default NewsApp;
