import React, { useState, useCallback } from 'react';
import styles from './NewsItem.module.css';
import FullNewsItem from './FullNewsItem';

const NewsItem = ({ question, answer, fullText, source, image }) => {
    const [isBlurred, setBlurred] = useState(true);
    const [isFullViewVisible, setFullViewVisible] = useState(false);

    const handleClick = useCallback(() => {
        if (isBlurred) {
            setBlurred(false);
        } else {
            setFullViewVisible(true);
        }
    }, [isBlurred]);

    const handleClose = useCallback(() => {
        setFullViewVisible(false);
    }, []);

    return (
        <>
            <div className={styles.item} onClick={handleClick}>
                <div className={`${styles.background} ${isBlurred ? styles.blurred : ''}`} style={{ backgroundImage: `url(${image})` }} />
                <div className={styles.content}>
                    <h2 className={styles.question}>{question}</h2>
                    <p className={`${styles.answer} ${isBlurred ? styles.blurred : styles.visible}`}>
                        {answer}
                    </p>
                </div>
            </div>
            {isFullViewVisible && (
                <FullNewsItem
                    question={question}
                    fullText={fullText}
                    source={source}
                    onClose={handleClose}
                />
            )}
        </>
    );
};

export default NewsItem;
