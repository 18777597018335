import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyAbRNcawNsCNEKPSacSKK3uU5jkLVKsf5A",
  authDomain: "algesnews.firebaseapp.com",
  projectId: "algesnews",
  storageBucket: "algesnews.appspot.com",
  messagingSenderId: "448554052012",
  appId: "1:448554052012:web:8a39e89a0cbb08b7117209"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };
