import React, { useRef, useEffect } from 'react';
import '../styles/ScrollFeed.css';

const ScrollFeed = ({ previousElement, currentElement, nextElement, onPreviousElement, onNextElement }) => {
    const containerRef = useRef(null);
    const scrollTimeoutRef = useRef(null);

    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            container.scrollTop = container.clientHeight;
        }
    }, [currentElement]);

    const handleScroll = () => {
        if (scrollTimeoutRef.current) {
            clearTimeout(scrollTimeoutRef.current);
        }

        scrollTimeoutRef.current = setTimeout(() => {
            const container = containerRef.current;
            const itemHeight = container.clientHeight;
            const scrollPosition = container.scrollTop;

            if (scrollPosition < itemHeight * 0.5) {
                container.scrollTo({
                    top: itemHeight,
                    behavior: 'smooth'
                });
                onPreviousElement();
            } else if (scrollPosition > itemHeight * 1.5) {
                container.scrollTo({
                    top: itemHeight,
                    behavior: 'smooth'
                });
                onNextElement();
            } else {
                container.scrollTo({
                    top: itemHeight,
                    behavior: 'smooth'
                });
            }
        }, 150);
    };

    return (
        <div
            ref={containerRef}
            className="scroll-container"
            onScroll={handleScroll}
        >
            <div className="scroll-item">
                {previousElement}
            </div>
            <div className="scroll-item">
                {currentElement}
            </div>
            <div className="scroll-item">
                {nextElement}
            </div>
        </div>
    );
};

export default ScrollFeed;
