import React from 'react';
import styles from './FullNewsItem.module.css';

const FullNewsItem = ({ question, fullText, source, onClose }) => {
  const handleSourceClick = (e) => {
    e.stopPropagation();
    window.open(source, '_blank');
    onClose();
  };

  return (
      <div className={styles.overlay} onClick={onClose}>
        <div className={styles.content} onClick={(e) => e.stopPropagation()}>
          <button className={styles.closeButton} onClick={onClose}>
            &#x2715;
          </button>
          <h2 className={styles.question}>{question}</h2>
          <p className={styles.fullText}>{fullText}</p>
          <button className={styles.sourceLink} onClick={handleSourceClick}>
            Перейти до джерела
          </button>
        </div>
      </div>
  );
};

export default FullNewsItem;
